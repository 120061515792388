/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

import axios from "helpers/axios";
import useAuthStore from 'stores/auth';
import { useTranslation } from 'react-i18next';
import { Modal, TextInput } from '@mantine/core';

import Logo from "assets/logo.png";
import showNotification from 'helpers/notification';

const Login = () => {

    const navigate = useNavigate()
    const { setToken, setUser } = useAuthStore()
    const { t, i18n } = useTranslation();

    useEffect(() => {
        setUser({})
        setToken(null)
    }, [])

    const [credentials, setCredentials] = useState({ username: "", password: "" });
    const [resetModal, setResetModal] = useState(false);
    const [username, setUsername] = useState("");

    const onSubmit = async (e) => {
        try {
            e.preventDefault();
            const { data: login_result } = await axios.post(`/auth/login`, credentials);
            setToken(login_result?.access_token)
            setUser(login_result?.user)
            navigate("/dashboard")
        } catch (error) {
            showNotification(t, false, error?.response?.data?.message);
        }
    };

    const handleResetPassword = async () => {
        try {
            const { data: result } = await axios.post(`/auth/reset-password`, { username });
            showNotification(t, true, result.message);
            handleOnClose();
        } catch (error) {
            showNotification(t, false, error?.response?.data?.message);
        }

    }

    const handleOnClose = () => {
        setResetModal(false)
        setUsername("")
    }

    return (
        <div className="min-h-screen w-full flex flex-col bg-gray-50">
            <div className='w-full bg-white shadow-sm flex flex-row items-center justify-center'>
                <div className='container mx-auto flex flex-row items-center justify-between'>
                    <div></div>
                    <h1 className='text-xl py-8 text-center'>{t('app_description')}</h1>
                    <div className='flex flex-row items-center justify-end gap-x-2'>
                        <button onClick={() => i18n.changeLanguage("tr")} className={`${i18n.language === "tr" ? "opacity-100" : "opacity-50"}`}><img src="/flag/tr.png" alt="" /></button>
                        <button onClick={() => i18n.changeLanguage("en")} className={`${i18n.language === "en" ? "opacity-100" : "opacity-50"}`}><img src="/flag/en.png" alt="" /></button>
                    </div>
                </div>
            </div>
            <div className="w-full flex-1 flex flex-col items-center justify-center">
                <form className='w-4/12 h-auto' method="POST" action='#' onSubmit={onSubmit}>
                    <div>
                        <div className='w-full mb-6'>
                            <img className="mx-auto object-contain" src={Logo} alt="" style={{ height: 60 }} />
                        </div>
                        <div>
                            <input
                                value={credentials?.username}
                                onChange={(e) => setCredentials(prev => ({ ...prev, username: e.target.value }))}
                                name='username'
                                required
                                className="w-full px-6 py-6 border border-border font-medium focus:outline-none sm:text-sm sm:leading-5"
                                placeholder={t('auth.username')}
                            />
                        </div>
                        <div className="-mt-px">
                            <input
                                value={credentials?.password}
                                onChange={(e) => setCredentials(prev => ({ ...prev, password: e.target.value }))}
                                type="password"
                                required
                                className="w-full px-6 py-6 border border-border-0 placeholder-bg0 font-medium focus:outline-none sm:text-sm sm:leading-5"
                                placeholder={t('auth.password')}
                            />
                        </div>
                    </div>
                    <div className='flex flex-row items-center justify-end mt-4'>
                        <button onClick={() => setResetModal(true)} type="button" className='text-sm' href="">{t('auth.reset_link')}</button>
                    </div>
                    <button type="submit" className="mt-4 cursor-pointer text-center w-full rounded-sm py-3 px-4 text-sm font-bold text-white bg-red-500 hover:bg-red-600 focus:outline-none">{t('auth.button')}</button>
                </form>
            </div>
            <div className='py-8'>
                <div className='container mx-auto flex flex-row items-center justify-between'>
                    <span className='text-center text-sm font-light'>&copy; 2024 - Gürpilsan Plastik</span>
                    <span className='text-center text-sm font-light'>fibacode.com — Digital Reklam ve Yazılım</span>
                </div>
            </div>

            <Modal opened={resetModal} size="lg" centered onClose={handleOnClose} title={t('auth.reset_title')}>
                <div className='flex flex-col gap-y-4'>
                    <TextInput size='md' placeholder={t('auth.reset_placeholder')} value={username} onChange={e => setUsername(e.target.value)} style={{ flex: 1 }} />
                    <button onClick={handleResetPassword} className='bg-red-500 text-white py-2 text-sm rounded'>{t('auth.reset_button')}</button>
                </div>
            </Modal>

        </div>
    );
}

export default Login;
