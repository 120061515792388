// RiUserSettingsLine

import { Input, LoadingOverlay } from "@mantine/core"
import axios from "helpers/axios";
import showNotification from "helpers/notification";
import checkPasswordStrengt from "helpers/password";
import { randomStr } from "helpers/unique";
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";
import { RiAiGenerate, RiUserSettingsLine } from "react-icons/ri"
import { useNavigate } from "react-router-dom";
import useUserStore from "stores/auth";

const Profile = () => {

    const navigate = useNavigate();
    const { user } = useUserStore();
    const { t } = useTranslation();

    const [loading, setLoading] = useState(true);
    const [entity, setEntity] = useState({});

    useEffect(() => {
        handleGetUserProfile();
    }, [])

    const handleGetUserProfile = async () => {
        try {
            const { data: user_result } = await axios.get("/profile");
            setEntity(user_result.user)
        } catch (error) {
            showNotification(t, false, error?.response?.data?.message);
        } finally {
            setLoading(false)
        }
    }

    const handleUpdateUserProfile = async () => {
        try {
            if (entity.password?.length > 0 && checkPasswordStrengt(entity.password) !== 5) {
                showNotification(t, false, t('alert.password'));
                return;
            }
            const { data: result } = await axios.post("/profile", entity);
            showNotification(t, true, result.message);
            navigate(-1)
        } catch (error) {
            showNotification(t, false, error?.response?.data?.message);
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className="bg-slate-50 muhsin flex flex-col">
            <LoadingOverlay className='fixed left-0 right-0 top-0 bottom-0 z-[100]' visible={!!loading} overlayBlur={5} />

            <div className='flex-1 flex flex-col'>

                <div className='bg-red-500'>
                    <div className='container mx-auto flex flex-row items-center py-6'>
                        <RiUserSettingsLine color='white' size={18} />
                        <h1 className='text-white text-lg font-light pl-2'>{t('pages.profile.top')}</h1>
                    </div>
                </div>

                <div className='container mx-auto grid grid-cols-1 gap-x-6 gap-y-6 py-10'>

                    <div className='bg-white rounded shadow-sm border border-slate-100 border-b-transparent'>
                        <div className='h-[70px] flex flex-row items-center justify-between px-6 border-b border-b-slate-100'>
                            <div className='flex-1 flex flex-row'>
                                <h1 className='text-lg font-bold'>{t('pages.profile.title_edit')}</h1>
                            </div>
                        </div>
                        <div className='flex-1 grid grid-cols-12 gap-x-6 gap-y-3 p-6'>

                            <div className="col-span-12">
                                <label className="text-base font-light mb-1 flex">{t('form.user_code')}:</label>
                                <Input size="md" value={entity.username} disabled />
                            </div>

                            <div className='col-span-12'>
                                <span className='font-light mb-1 block'>{t('form.password')}:</span>
                                <Input.Wrapper error={entity.password?.length > 0 && checkPasswordStrengt(entity.password) !== 5 ? t("alert.password") : ""}>
                                    <Input value={entity.password} error={entity.password?.length > 0 && checkPasswordStrengt(entity.password) !== 5} onChange={e => setEntity(prev => ({ ...prev, password: e.target.value }))} rightSection={<button onClick={() => setEntity(prev => ({ ...prev, password: randomStr(15) }))}><RiAiGenerate /></button>} size="md" />
                                </Input.Wrapper>
                            </div>

                            {user.type === "client" && <>
                                <div className="col-span-12">
                                    <label className="text-base font-light mb-1 flex">{t('form.company')}:</label>
                                    <Input size="md" value={entity.name} disabled />
                                </div>
                                <div className="col-span-6">
                                    <label className="text-base font-light mb-1 flex">{t('form.address')}:</label>
                                    <Input size="md" value={entity.address} disabled />
                                </div>
                                <div className="col-span-6">
                                    <label className="text-base font-light mb-1 flex">{t('form.country')}:</label>
                                    <Input size="md" value={entity.country} disabled />
                                </div>
                                <div className="col-span-6">
                                    <label className="text-base font-light mb-1 flex">{t('form.tax')}:</label>
                                    <Input size="md" value={entity.tax} disabled />
                                </div>
                                <div className="col-span-6">
                                    <label className="text-base font-light mb-1 flex">{t('form.eori')}:</label>
                                    <Input size="md" value={entity.eori} disabled />
                                </div>
                                <div className="col-span-4">
                                    <label className="text-base font-light mb-1 flex">{t('form.phone')}:</label>
                                    <Input size="md" value={entity.phone} disabled />
                                </div>
                                <div className="col-span-4">
                                    <label className="text-base font-light mb-1 flex">{t('form.fax')}:</label>
                                    <Input size="md" value={entity.fax} disabled />
                                </div>
                                <div className="col-span-4">
                                    <label className="text-base font-light mb-1 flex">{t('form.website')}:</label>
                                    <Input size="md" value={entity.website} disabled />
                                </div>
                                <div className="col-span-12">
                                    <label className="text-base font-light mb-1 flex">{t('form.email')}:</label>
                                    <Input size="md" value={entity.emails} disabled />
                                </div>
                            </>}

                            <button onClick={handleUpdateUserProfile} className="col-span-12 py-4 bg-green-500 text-white text-sm">{t('form.button_update')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Profile