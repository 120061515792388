import React, { useState } from 'react';

import { createColumnHelper } from '@tanstack/react-table';
import { Input, LoadingOverlay, Select, Tooltip } from '@mantine/core';
import { RiArrowRightLine, RiShutDownLine, RiTaskLine } from "react-icons/ri";

import DataTable from 'components/table';

import { Link } from 'react-router-dom';
import axios from 'helpers/axios';
import useUserStore from 'stores/auth';
import { useTranslation } from 'react-i18next';
import useDebounce from 'helpers/debounce';
import { RenderIcon } from 'helpers/task';
import showNotification from 'helpers/notification';

export default function OrderList() {

    const columnHelper = createColumnHelper()
    const { user } = useUserStore();
    const { t } = useTranslation();

    const [loading, setLoading] = useState(true);

    const [tasks, setTasks] = useState([]);
    const [filters, setFilters] = useState({ status: true });

    useDebounce(() => {
        handleGetAllCampaigns();
    }, [filters], 250);

    const handleGetAllCampaigns = async () => {
        try {
            setLoading(true)
            const { data: results } = await axios.get("/tasks", { params: filters });
            setTasks(results?.tasks || [])
        } catch (error) {
            showNotification(t, false, error?.response?.data?.message);
        } finally {
            setLoading(false)
        }
    }

    const handleUpdateTask = async (id) => {
        try {
            setLoading(true)
            await axios.delete(`/tasks/${id}`);
            handleGetAllCampaigns();
        } catch (error) {
            showNotification(t, false, error?.response?.data?.message);
        } finally {
            setLoading(false)
        }
    }

    const columns = [
        columnHelper.accessor('order_code', {
            header: t('table.order_code'),
            full: true
        }),
        columnHelper.accessor('product_code', {
            header: t('table.product_code'),
        }),
        columnHelper.accessor('client_name', {
            header: t('table.client_name'),
        }),
        columnHelper.accessor('type', {
            header: t('table.type'),
            truncate: true
        }),
        columnHelper.accessor('weight', {
            header: t('table.weight'),
            truncate: true
        }),
        columnHelper.accessor('qty', {
            header: t('table.qty'),
            truncate: true
        }),
        columnHelper.accessor('status', {
            header: t('table.status'),
            truncate: true,
            cell: (row) => <RenderIcon code={row.getValue()} type={row.row.original.user_type} automatic={row.row.original.automatic} />
        }),
        columnHelper.accessor('id', {
            header: t('table.actions'),
            cell: row => {
                return (
                    <div className={`w-max grid ${user.type === "manager" ? "grid-cols-2" : "grid-cols-1"} gap-x-2`}>
                        {user.type === "manager" && (
                            <Tooltip label={row.row.original.is_hidden ? t('table.button_toggle_show') : t('table.button_toggle_hide')}>
                                <button onClick={() => handleUpdateTask(row.getValue())} className={`flex cursor-pointer w-8 h-8 ${row.row.original.is_hidden ? "bg-green-500" : "bg-red-500"} rounded flex flex-row items-center justify-center`}>
                                    <RiShutDownLine color="white" size={15} />
                                </button>
                            </Tooltip>
                        )}
                        <Tooltip label={t('table.button_review')}>
                            <Link to={`/tasks/${row.getValue()}`} className='flex cursor-pointer w-8 h-8 bg-blue-500 rounded flex flex-row items-center justify-center'>
                                <RiArrowRightLine color="white" size={15} />
                            </Link>
                        </Tooltip>
                    </div>
                )
            }
        }),
    ]

    return (
        <div className="bg-slate-50 muhsin flex flex-col">

            <LoadingOverlay className='fixed left-0 right-0 top-0 bottom-0 z-[100]' visible={!!loading} overlayBlur={5} />

            <div className='flex-1 flex flex-col'>

                <div className='bg-red-500'>
                    <div className='container mx-auto flex flex-row items-center py-6'>
                        <RiTaskLine color="white" size={18} />
                        <h1 className='text-white text-lg font-light pl-2'>{t('pages.task.top')}</h1>
                    </div>
                </div>

                <div className='container mx-auto grid grid-cols-1 gap-x-6 gap-y-6 py-10'>

                    <div className='bg-white rounded shadow-sm border border-slate-100 border-b-transparent'>
                        <div className='h-[70px] flex flex-row items-center justify-between px-6 border-b border-b-slate-100'>
                            <div className='flex-1 flex flex-row'>
                                <h1 className='text-lg font-bold'>{t('filter.title')}</h1>
                            </div>
                        </div>
                        <form className='w-full overflow-x-auto p-6 flex flex-row gap-x-6 gap-y-3' onSubmit={handleGetAllCampaigns}>
                            <div className='flex-1'>
                                <label className='text-base font-light flex mb-1'>{t('filter.order')}:</label>
                                <Input size="md" placeholder={t('filter.placeholder')} value={filters.order} onChange={(e) => setFilters(prev => ({ ...prev, order: e.target.value }))} />
                            </div>
                            <div className='flex-1'>
                                <label className='text-base font-light flex mb-1'>{t('filter.product')}:</label>
                                <Input size="md" placeholder={t('filter.placeholder')} value={filters.product} onChange={(e) => setFilters(prev => ({ ...prev, product: e.target.value }))} />
                            </div>
                            {user.type !== "client" && (
                                <div className='flex-1'>
                                    <label className='text-base font-light flex mb-1'>{t('filter.client')}:</label>
                                    <Input size="md" placeholder={t('filter.placeholder')} value={filters.client} onChange={(e) => setFilters(prev => ({ ...prev, client: e.target.value }))} />
                                </div>
                            )}
                            <div className='flex-1'>
                                <label className='text-base font-light flex mb-1'>{t('filter.status')}:</label>
                                <Select size="md" value={filters.status} onChange={(e) => setFilters(prev => ({ ...prev, status: e }))} data={[
                                    { value: null, label: "-" },
                                    { value: true, label: t('boolean.true') },
                                    { value: false, label: t('boolean.false') }
                                ]} />
                            </div>
                        </form>
                    </div>

                    <div className='bg-white rounded shadow-sm border border-slate-100 border-b-transparent'>
                        <div className='h-[70px] flex flex-row items-center justify-between px-6 border-b border-b-slate-100'>
                            <div className='flex-1 flex flex-row'>
                                <h1 className='text-lg font-bold'>{t('pages.task.title_list')}</h1>
                            </div>
                        </div>
                        <div className='w-full overflow-x-auto'>
                            <DataTable paginate columns={columns} data={tasks} message={t('no')} />
                        </div>
                    </div>

                </div>

            </div>

        </div>
    );
}