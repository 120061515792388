import React, { useState } from 'react';

import { createColumnHelper } from '@tanstack/react-table';
import { Input, LoadingOverlay, Select, Tooltip } from '@mantine/core';
import { RiCloseLine, RiEditLine, RiExternalLinkLine, RiListOrdered, RiMailSendLine } from "react-icons/ri";

import DataTable from 'components/table';

import { Link } from 'react-router-dom';
import axios from 'helpers/axios';
import { BottomSheet } from 'react-spring-bottom-sheet';
import useUserStore from 'stores/auth';
import { useTranslation } from 'react-i18next';
import useDebounce from 'helpers/debounce';
import { RenderIcon } from 'helpers/task';
import showNotification from 'helpers/notification';

export default function OrderList() {

    const columnHelper = createColumnHelper()
    const { user } = useUserStore();
    const { t } = useTranslation();

    const [loading, setLoading] = useState(true);
    const [isOpen, setOpen] = useState(null);
    const [filters, setFilters] = useState({ uncompleted: true });

    const [orders, setOrders] = useState([]);

    const [order, setOrder] = useState({});

    useDebounce(() => {
        handleGetAllCampaigns();
    }, [filters], 250);

    const handleGetAllCampaigns = async () => {
        try {
            setLoading(true)
            const { data: results } = await axios.get("/orders", { params: filters });
            setOrders(results?.orders || [])
        } catch (error) {
            showNotification(t, false, error?.response?.data?.message);
        } finally {
            setLoading(false)
        }
    }

    const handleStart = async (id) => {
        try {
            const { data: results } = await axios.get(`/orders/${id}/show`);
            setOrder({ ...results.order, items: results.items })
            setOpen(true)
        } catch (error) {
            showNotification(t, false, error?.response?.data?.message);
        } finally {
            setLoading(false)
        }

    }

    const handleSendMail = async (id) => {
        try {
            const { data: result } = await axios.post(`/orders/${id}/mail`);
            showNotification(t, true, result.message);
            handleGetAllCampaigns();
        } catch (error) {
            showNotification(t, false, error?.response?.data?.message);
        } finally {
            setLoading(false)
        }
    }

    const columns = [
        columnHelper.accessor('code', {
            header: t('table.order_code'),
            truncate: true,
        }),
        columnHelper.accessor('client_name', {
            header: t('table.client_name'),
            truncate: true,
            full: true,
        }),
        columnHelper.accessor('uncompleted', {
            header: t('table.status'),
            truncate: true,
            cell: (row) => row.getValue() > 0 ? t('boolean.continue') : t('boolean.completed')
        }),
        columnHelper.accessor('created_at', {
            header: t('table.created_at'),
            truncate: true,
            date: true
        }),
        columnHelper.accessor('id', {
            header: t('table.actions'),
            cell: row => {
                return (
                    <div className={`w-max grid ${user.type === "manager" ? "grid-cols-3" : "grid-cols-1"} gap-x-2`}>

                        {user.type === "manager" && (
                            <Tooltip label={t('table.button_mail')}>
                                <button onClick={() => handleSendMail(row.getValue())} className={`flex cursor-pointer w-8 h-8 ${row.row.original.is_send ? "bg-slate-100" : "bg-lime-500"} rounded flex flex-row items-center justify-center`}>
                                    <RiMailSendLine color={row.row.original.is_send ? "black" : "white"} size={16} />
                                </button>
                            </Tooltip>
                        )}

                        <Tooltip label={t('table.button_show')}>
                            <button onClick={() => handleStart(row.getValue())} className='flex cursor-pointer w-8 h-8 bg-green-500 rounded flex flex-row items-center justify-center'>
                                <RiExternalLinkLine color="white" size={16} />
                            </button>
                        </Tooltip>

                        {user.type === "manager" && (
                            <>
                                <Tooltip label={t('table.button_edit')}>
                                    <Link to={`/orders/${row.getValue()}/edit`} className='flex cursor-pointer w-8 h-8 bg-blue-500 rounded flex flex-row items-center justify-center'>
                                        <RiEditLine color="white" size={16} />
                                    </Link>
                                </Tooltip>
                            </>
                        )}
                    </div>
                )
            }
        }),
    ]

    const handleDimiss = () => {
        setOpen(null);
        setOrder({})
    }

    return (
        <div className="bg-slate-50 muhsin flex flex-col">

            <LoadingOverlay className='fixed left-0 right-0 top-0 bottom-0 z-[100]' visible={!!loading} overlayBlur={5} />

            <div className='flex-1 flex flex-col'>

                <div className='bg-red-500'>
                    <div className='container mx-auto flex flex-row items-center py-6'>
                        <RiListOrdered color='white' size={18} />
                        <h1 className='text-white text-lg font-light pl-2'>{t('pages.order.top')}</h1>
                    </div>
                </div>

                <div className='container mx-auto grid grid-cols-1 gap-x-6 gap-y-6 py-10'>

                    <div className='bg-white rounded shadow-sm border border-slate-100 border-b-transparent'>
                        <div className='h-[70px] flex flex-row items-center justify-between px-6 border-b border-b-slate-100'>
                            <div className='flex-1 flex flex-row'>
                                <h1 className='text-lg font-bold'>{t('filter.title')}</h1>
                            </div>
                        </div>
                        <form className='w-full overflow-x-auto p-6 flex flex-row gap-x-6 gap-y-3' onSubmit={handleGetAllCampaigns}>

                            <div className='flex-1'>
                                <label className='text-base font-light flex mb-1'>{t('filter.order')}:</label>
                                <Input size="md" placeholder={t('filter.placeholder')} value={filters.order} onChange={(e) => setFilters(prev => ({ ...prev, order: e.target.value }))} />
                            </div>

                            {user.type !== "client" && (
                                <div className='flex-1'>
                                    <label className='text-base font-light flex mb-1'>{t('filter.client')}:</label>
                                    <Input size="md" placeholder={t('filter.placeholder')} value={filters.client} onChange={(e) => setFilters(prev => ({ ...prev, client: e.target.value }))} />
                                </div>
                            )}

                            <div className='flex-1'>
                                <label className='text-base font-light flex mb-1'>{t('filter.status')}:</label>
                                <Select size="md" value={filters.uncompleted} onChange={(e) => setFilters(prev => ({ ...prev, uncompleted: e }))}
                                    data={[
                                        { value: null, label: "-" },
                                        { value: true, label: t('boolean.continue') },
                                        { value: false, label: t('boolean.completed') }
                                    ]}
                                />
                            </div>

                        </form>
                    </div>

                    <div className='bg-white rounded shadow-sm border border-slate-100 border-b-transparent'>
                        <div className='h-[70px] flex flex-row items-center justify-between px-6 border-b border-b-slate-100'>
                            <div className='flex-1 flex flex-row'>
                                <h1 className='text-lg font-bold'>{t('pages.order.title_list')}</h1>
                            </div>
                            {user.type === "manager" && (
                                <div className='flex flex-row gap-x-1'>
                                    <Link to={"/orders/create"} className='bg-green-500 px-4 py-2 rounded text-sm text-white'>{t('pages.order.title_create')}</Link>
                                </div>
                            )}
                        </div>
                        <div className='w-full overflow-x-auto'>
                            <DataTable paginate columns={columns} data={orders} />
                        </div>
                    </div>

                </div>
            </div>

            <BottomSheet onDismiss={handleDimiss} open={!!isOpen} scrollLocking={true} maxHeight={window.screen.height / 1.5} header={false}>
                <div className='bg-red-500'>
                    <div className='container mx-auto flex flex-row items-center justify-between py-5 text-lg font-light text-white'>
                        <h1>{t('pages.order.title_show')}</h1>
                        <button onClick={() => setOpen(false)}><RiCloseLine /></button>
                    </div>
                </div>
                <div className='container mx-auto grid grid-cols-12 py-6 gap-4'>
                    <div className='col-span-6 flex flex-col font-bold'>{t('table.client_name')}: <strong className='font-light'>{order.client}</strong></div>
                    <div className='col-span-6 flex flex-col font-bold'>{t('table.order_code')}: <strong className='font-light'>{order.code}</strong></div>
                    <div className='col-span-12 mt-2'>
                        <h1 className='w-full text-lg font-bold mb-2 uppercase'>{t('table.content')}</h1>
                        <table class="w-full table-auto border-collapse border border-slate-500">
                            <thead>
                                <tr className='bg-slate-100'>
                                    <td className='text-center border border-slate-100 py-3 text-sm'>#</td>
                                    <td className='text-center border border-slate-100 py-3 text-sm'>{t('form.product')}</td>
                                    <td className='text-center border border-slate-100 py-3 text-sm'>{t('form.type')}</td>
                                    <td className='text-center border border-slate-100 py-3 text-sm'>{t('form.qty')}</td>
                                    <td className='text-center border border-slate-100 py-3 text-sm'>{t('form.weight')}</td>
                                    <td className='text-center border border-slate-100 py-3 text-sm'>{t('form.status')}</td>
                                    {user.type === "manager" && (
                                        <td className='text-center border border-slate-100 py-3 text-sm'>{t('table.actions')}</td>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {order?.items?.map((a, i) => {
                                    return (
                                        <tr className='text-center'>
                                            <td className='border border-slate-100 px-2 py-3 text-sm font-light'>{i + 1}</td>
                                            <td className='border border-slate-100 px-2 py-3 text-sm font-light'>{a.product_code}</td>
                                            <td className='border border-slate-100 px-2 py-3 text-sm font-light'>{t(`type.${a.type}`)}</td>
                                            <td className='border border-slate-100 px-2 py-3 text-sm font-light'>{a.qty}</td>
                                            <td className='border border-slate-100 px-2 py-3 text-sm font-light'>{a.weight}</td>
                                            <td className='border border-slate-100 px-2 py-3 text-sm font-light'>
                                                <div className='flex flex-row items-center justify-center'>
                                                    <RenderIcon code={a.status} type={a.user_type} automatic={order.automatic} />
                                                </div>
                                            </td>
                                            {user.type === "manager" && (
                                                <td className='border border-slate-100 px-2 py-3 text-sm font-light'>
                                                    <div className='flex flex-row items-center justify-center gap-x-2'>
                                                        <>
                                                            <Tooltip label={t('table.button_edit')}>
                                                                <Link to={`/tasks/${a.id}`} className='flex cursor-pointer w-8 h-8 bg-blue-500 rounded flex flex-row items-center justify-center'>
                                                                    <RiEditLine color="white" size={16} />
                                                                </Link>
                                                            </Tooltip>
                                                        </>
                                                    </div>
                                                </td>
                                            )}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </BottomSheet>

        </div>
    );
}