import axios from 'axios';
import { getI18n } from 'react-i18next';
import useAuthStore from 'stores/auth';

export const BASE_URL = true ? "https://quality.gurpilsan.com/api" : "http://localhost:8000"
// export const BASE_URL = false ? "https://gurpilsan-api.fibacode.com" : "http://localhost:8000"

const axios_client = axios.create({ baseURL: BASE_URL });

axios_client.interceptors.request.use(config => {
    config.headers["Accept-Language"] = getI18n().language;
    let token = useAuthStore.getState().token;
    if (token) config.headers.Authorization = token
    return config;
}, err => err);

axios_client.interceptors.response.use((res) => res, (err) => {
    if (err?.response?.status === 401) {
        delete axios_client.defaults.headers.authorization;
        window.location.href = "/login"
    }
    throw err;
});

export default axios_client;
