import { Tooltip } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { RiCheckboxCircleLine, RiCloseCircleLine, RiLoaderLine } from "react-icons/ri";

export const RenderIcon = ({ code, type, automatic }) => {

    const { t } = useTranslation();

    console.log(code, type)

    var sectures = {
        "init_manager": (
            <div className='flex flex-row gap-x-1'>
                <Tooltip label={t('status.quality_wait')}>
                    <div><RiLoaderLine className='text-xl text-slate-500 animate-spin' /></div>
                </Tooltip>
                <Tooltip label={t('status.manager_wait')}>
                    <div><RiLoaderLine className='text-xl text-slate-500 animate-spin' /></div>
                </Tooltip>
                <Tooltip label={t('status.client_wait')}>
                    <div><RiLoaderLine className='text-xl text-slate-500 animate-spin' /></div>
                </Tooltip>
            </div>
        ),
        "done_quality": (
            <div className='flex flex-row gap-x-1'>
                <Tooltip label={t('status.quality_success')}>
                    <div><RiCheckboxCircleLine className='text-xl text-green-600' /></div>
                </Tooltip>
                <Tooltip label={t('status.manager_wait')}>
                    <div><RiLoaderLine className='text-xl text-slate-500 animate-spin' /></div>
                </Tooltip>
                <Tooltip label={t('status.client_wait')}>
                    <div><RiLoaderLine className='text-xl text-slate-500 animate-spin' /></div>
                </Tooltip>
            </div>
        ),
        "done_manager": (
            <div className='flex flex-row gap-x-1'>
                <Tooltip label={t('status.quality_success')}>
                    <div><RiCheckboxCircleLine className='text-xl text-green-600' /></div>
                </Tooltip>
                <Tooltip label={t('status.manager_success')}>
                    <div><RiCheckboxCircleLine className='text-xl text-green-600' /></div>
                </Tooltip>
                <Tooltip label={t('status.client_wait')}>
                    <div><RiLoaderLine className='text-xl text-slate-500 animate-spin' /></div>
                </Tooltip>
            </div>
        ),
        "reject_manager": (
            <div className='flex flex-row gap-x-1'>
                <Tooltip label={t('statusz.quality_success')}>
                    <div><RiCheckboxCircleLine className='text-xl text-green-600' /></div>
                </Tooltip>
                <Tooltip label={t('status.manager_fail')}>
                    <div><RiCloseCircleLine className='text-xl text-red-600' /></div>
                </Tooltip>
                <Tooltip label={t('status.client_wait')}>
                    <div><RiLoaderLine className='text-xl text-slate-500 animate-spin' /></div>
                </Tooltip>
            </div>
        ),
        "done_client": (
            <div className='flex flex-row gap-x-1'>
                <Tooltip label={t('status.quality_success')}>
                    <div><RiCheckboxCircleLine className='text-xl text-green-600' /></div>
                </Tooltip>
                <Tooltip label={t('status.manager_success')}>
                    <div><RiCheckboxCircleLine className='text-xl text-green-600' /></div>
                </Tooltip>
                <Tooltip label={t('status.client_success')}>
                    <div><RiCheckboxCircleLine className='text-xl text-green-600' /></div>
                </Tooltip>
            </div>
        ),
        "reject_client": (
            <div className='flex flex-row gap-x-1'>
                <Tooltip label={t('status.quality_success')}>
                    <div><RiCheckboxCircleLine className='text-xl text-green-600' /></div>
                </Tooltip>
                <Tooltip label={t('status.manager_success')}>
                    <div><RiCheckboxCircleLine className='text-xl text-green-600' /></div>
                </Tooltip>
                <Tooltip label={t('status.client_fail')}>
                    <div><RiCloseCircleLine className='text-xl text-red-600' /></div>
                </Tooltip>
            </div>
        )
    };

    return sectures[`${code}_${type}`]
}